import { UsersService } from '../../../../services/users';
import { Grid } from '@mui/material';
import React from 'react';
import { IUserModel } from '../../../../types/user';
import DialogHeadline from '../../../shared/Dialogs/DialogHeadline';
import { Button } from '@elli-eco/component-library';
import useStyles from '../useStyles';

export interface IOwnProps {
  user: IUserModel;
  onSuccess: () => void;
  onFail: (message: string) => void;
}

const DeactivateForm: React.FC<IOwnProps> = ({ user, onSuccess, onFail }) => {
  const classes = useStyles();

  const yesCallback = () => {
    if (user.disabled) {
      UsersService.activateUser({ userId: user.id })
        .then(onSuccess)
        .catch(onFail);
    } else {
      UsersService.deactivateUser({ userId: user.id })
        .then(onSuccess)
        .catch(onFail);
    }
  };

  return (
    <div className=''>
      <div>
        {user.disabled ? (
          <DialogHeadline
            headline={'Activate User'}
            subHeadline={'Are you sure?'}
          />
        ) : (
          <DialogHeadline
            headline={'Deactivate User'}
            subHeadline={'Are you sure?'}
          />
        )}
      </div>
      <Grid item xs={12} className={classes.submitWrapper}>
        <Button variant='outlined' onClick={yesCallback}>
          yes
        </Button>
      </Grid>
    </div>
  );
};

export default DeactivateForm;
