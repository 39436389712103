import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { IUserModel } from '../../../types/user';
import { formatDateNoTime } from '../../../utils/dates';
import { getCountry } from '../../../utils/getCountryFromCode';
import connector, { IPropsFromState } from '../../Connector/Connector';
import { DealershipLogo } from '../../DealershipLogo/DealershipLogo';
import CardElement from '../../shared/CardElement/CardElement';
import InformationItem from '../../shared/InformationItems/InformationItem';
import UserVerified from '../../shared/UserVerified/UserVerified';
import Tooltip from '../../ui/Tooltip';
import { UserIds } from './UserIds';
import useStyles from './useStyles';
import { getDealershipName } from '../../../utils/TenantData';
import {
  nullSubscription,
  setAvailableSubscriptionData,
} from '../PublicCharging';
import { ISubscriptionModel } from '../../../types/subscriber';
import { ITariffModel } from '../../../types/tariff';
import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import useSharedStyles from '../../shared/useSharedStyles';
import { useTariffsQuery } from '../../../state/queries/tariffs';
import { Typography } from '../../shared/Typography/Typography';
import EditIcon from '../../../assets/image/edit.svg';
import { IActionItem } from '../../shared/ActionDropdownContainer';
import ChangeUserProfileModal from './ChangeUserForm/ChangeUserProfileModal';
import { useWrappedAuth0Hook } from '../../../custom-hooks/useWrappedAuth0Hook';
import { useCCUserRolesQuery } from '../../../state/queries/ccUserRoles';
import { DeactivateModal } from './DeactiveUser/DeactivateModal';

type TProps = IPropsFromState & {
  crumbId?: string;
};

const getFullName = (data: IUserModel): string => {
  if (data.first_name && data.last_name) {
    return `${data.first_name} ${data.last_name}`;
  }

  return '-';
};

export const UserUI: React.FC<TProps> = ({
  userState,
  subscriberState,
  subscriptionState,
}) => {
  const { t, terms } = useTypedTranslation();
  const classes = useStyles();

  const tariffs = useTariffsQuery();

  const user = userState;
  const subscriber = subscriberState;

  const [userId, setUserId] = useState<string>('');
  const [subscriberId, setSubscriberId] = useState<string>('');
  const [customerNumber, setCustomerNumber] = useState<string>('');
  const [tariff, setTariff] = useState<ITariffModel | null>(null);
  const [subscriptionData, setSubscriptionData] = useState<ISubscriptionModel>(
    nullSubscription
  );
  const { isAuthenticated } = useWrappedAuth0Hook();
  const { data: ccUserRoles } = useCCUserRolesQuery();

  const isCCAuthorized = Boolean(isAuthenticated && ccUserRoles?.isGdprManager);

  const actionItems: IActionItem[] = [
    {
      icon: <img src={EditIcon} alt='Edit User Profile' />,
      friendlyText: 'Edit User Profile',
      component: <ChangeUserProfileModal />,
      disabled: !subscriber?.data || !isCCAuthorized,
    },
    {
      icon: <img src={EditIcon} alt='Activate/Deactivate User' />,
      friendlyText: user?.data?.disabled ? 'Activate User' : 'Deactivate User',
      component: !user.data ? <></> : <DeactivateModal user={user.data} />,
      disabled: !user?.data || !isCCAuthorized,
    },
  ];

  useEffect(() => {
    if (user && user.data) {
      setUserId(user.data.id);
    }

    if (subscriber && subscriber.data) {
      setSubscriberId(subscriber.data.id);
      setCustomerNumber(subscriber.data.customer_number || '-');
    }

    if (user && user.data) {
      setUserId(user.data.id);
    }
  }, [user, subscriber]);

  useEffect(() => {
    if (
      subscriptionState &&
      subscriptionState.data &&
      subscriptionState.data.length > 0
    ) {
      setAvailableSubscriptionData(subscriptionState.data, setSubscriptionData);
    }
  }, [subscriptionState]);

  useEffect(() => {
    if (
      subscriptionData.tariff_id !== '-' &&
      !tariffs.isLoading &&
      !tariffs.isError
    ) {
      setTariff(
        tariffs.data?.find(({ id }) => id === subscriptionData.tariff_id) ||
          null
      );
    }
  }, [subscriptionData, tariffs.data]);

  const getItems = (userData: IUserModel) => {
    const sharedClasses = useSharedStyles();
    return [
      {
        id: 'customer-name',
        headline: t(terms.user_profile.name),
        content: getFullName(userData),
      },
      {
        id: 'customer-email-title',
        headline: (
          <>
            {t(terms.user_profile.email)}
            <div className={classes.tooltipWrapper}>
              <Tooltip tooltipName='more-info'>
                <UserIds
                  userId={userId}
                  subscriberId={subscriberId}
                  customerNumber={customerNumber}
                  subscriptionId={subscriptionData.id}
                  tariffCode={tariff?.code}
                />
              </Tooltip>
            </div>
          </>
        ),
        content: userData.email ? (
          <ElliTooltip title={userData.email}>
            <span className={sharedClasses.textOverflow}>{userData.email}</span>
          </ElliTooltip>
        ) : (
          '-'
        ),
      },
      {
        id: 'customer-account-creation',
        headline: t(terms.user_profile.customer_account_created),
        content: (
          <Typography data-testid='customer-account-creation'>
            {userData.created_at ? formatDateNoTime(userData.created_at) : '-'}
          </Typography>
        ),
      },
      {
        id: 'customer-last-login',
        content: userData.last_login
          ? formatDateNoTime(userData.last_login)
          : '-',
        headline: t(terms.user_profile.customer_last_login),
      },

      {
        id: 'customer-ecosystem',
        headline: t(terms.user_profile.ecosystem),
        content: (
          <>
            {userData.tenant_id && (
              <DealershipLogo
                id={userData.tenant_id}
                width='20px'
                height='20px'
              />
            )}
            <p className={classes.ecosystemView}>
              {getDealershipName(userData.tenant_id) ?? '-'}
            </p>
          </>
        ),
      },
      {
        id: 'customer-country',
        headline: t(terms.user_profile.signup_country),
        content: userData.country ? getCountry(userData.country) : '-',
      },
    ];
  };

  return (
    <div className={classes.contentWrapper} data-testid='user-ui-component'>
      <UserVerified />
      <CardElement
        title={t(terms.user_profile.box_title)}
        actionItems={actionItems}
        withActions={true}
      >
        {user.data && (
          <Grid container className={classes.detailWrapper}>
            {getItems(user.data).map((item) => (
              <Grid item xs={6} key={item.id}>
                <InformationItem item={item} />
              </Grid>
            ))}
          </Grid>
        )}
      </CardElement>
    </div>
  );
};

export default connector(UserUI);
