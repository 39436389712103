import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';
import {
  ICDRMgmtResponse,
  PricingModel,
} from '../../../../types/chargingSession';
import CpoGroupDialog from './CpoGroupDialog';
import { ChargingCondition } from '../../../../types/chargingCondition';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LegacyBlockingFeeTable from './LegacyBlockingFeeTable';
import { config } from '../../../../config';
import { BlockingFeeTable } from './BlockingFeeTable';
import { formatDurationInSeconds } from '../../../../utils/dates';
import { formatUnit } from '../../../../utils/numbers';

export type BreakdownTableProps = {
  chargeRecord: ICDRMgmtResponse;
  chargingCondition?: ChargingCondition;
};

const getBillableBlockingPeriod = (
  chargeRecord: ICDRMgmtResponse,
  chargingCondition?: ChargingCondition
) => {
  if (!chargingCondition) {
    return undefined;
  }
  if (
    chargeRecord.location_connector_power_type === 'DC' &&
    chargingCondition.blocking_fee_dc
  ) {
    return {
      start: chargingCondition.blocking_fee_dc?.application_start_local_time,
      end: chargingCondition.blocking_fee_dc?.application_end_local_time,
    };
  } else if (chargingCondition.blocking_fee_ac) {
    return {
      start: chargingCondition.blocking_fee_ac?.application_start_local_time,
      end: chargingCondition.blocking_fee_ac?.application_end_local_time,
    };
  }
  return undefined;
};

export const BreakdownTable = ({
  chargeRecord,
  chargingCondition,
}: BreakdownTableProps) => {
  const classes = useStyles();
  const [isCpoGroupDialogOpen, setIsCpoGroupDialogOpen] = useState(false);
  const priceBreakdown = chargeRecord.price_breakdown;

  const currency = chargeRecord.blocking_fee_currency;
  const formatCurrency = (value: number) => formatUnit(value, currency);

  const showLegacyBlockingFeeTable =
    (config.env === 'prod' || config.env === 'stage') &&
    Date.parse(chargeRecord.created_at) <
      Date.parse('2025-01-29 11:28:51.096 +0100'); // Creation date of CDR with new blocking fee logic

  const billableBlockingPeriod = getBillableBlockingPeriod(
    chargeRecord,
    chargingCondition
  );

  const consumptionFee = priceBreakdown?.charging_fee?.gross_amount ?? 0;

  const couponReduction = priceBreakdown?.coupon_reduction ?? 0;

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell size='small'>
              <Typography variant='h6'>Item</Typography>
            </TableCell>
            <TableCell align='right' size='small'>
              <Typography variant='h6'>Total</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ alignContent: 'flex-start' }}>
              <Typography variant='subtitle2' sx={{ display: 'inline' }}>
                Energy price according to consumption
              </Typography>
              {chargeRecord.cpo_party_id && (
                <>
                  <IconButton
                    data-testid='price-info-button'
                    onClick={() =>
                      setIsCpoGroupDialogOpen((prevState) => !prevState)
                    }
                    disableRipple
                    disableFocusRipple
                    size='large'
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                  <CpoGroupDialog
                    open={isCpoGroupDialogOpen}
                    onClose={() => setIsCpoGroupDialogOpen(false)}
                    cpoPartId={chargeRecord.cpo_party_id}
                  />
                </>
              )}
            </TableCell>
            <TableCell align='right'>
              <div>
                <Typography variant='subtitle2'>
                  {formatCurrency(consumptionFee)}
                </Typography>
                {/* replace chargingCondition with chargeRecord when we send fixed_pricing_unit and charging_fee_rate */}
                {chargingCondition &&
                  chargingCondition.pricing_model === PricingModel.Fixed && (
                    <Typography variant='h6'>
                      {chargingCondition.fixed_pricing_unit === 'min' ||
                      chargingCondition.fixed_pricing_unit === 's'
                        ? formatDurationInSeconds(
                            chargeRecord.total_time * 60 * 60
                          )
                        : formatUnit(
                            chargeRecord.total_energy,
                            chargingCondition.fixed_pricing_unit
                          )}
                      {' * '}
                      {formatCurrency(
                        chargeRecord.location_connector_power_type === 'DC'
                          ? chargingCondition.fixed_pricing_dc_price
                          : chargingCondition.fixed_pricing_ac_price
                      )}
                      /{chargingCondition.fixed_pricing_unit}
                    </Typography>
                  )}
                {chargeRecord.pricing_model === PricingModel.Markup &&
                  chargeRecord.price_breakdown?.charging_fee_rate
                    ?.gross_amount && (
                    <Typography variant='h6'>
                      {formatUnit(chargeRecord.total_energy, 'kWh')}
                      {' * '}
                      {formatCurrency(
                        chargeRecord.price_breakdown?.charging_fee_rate
                          ?.gross_amount
                      )}
                      / kWh
                    </Typography>
                  )}
              </div>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Pricing model</TableCell>
            <TableCell align='right'>
              {chargeRecord.pricing_model === PricingModel.Markup
                ? 'Markup'
                : 'Fixed'}
            </TableCell>
          </TableRow>
          {/* LegacyBlockingFeeTable can be removed after 2025-03-01 */}
          {showLegacyBlockingFeeTable && billableBlockingPeriod ? (
            <LegacyBlockingFeeTable
              chargeRecord={chargeRecord}
              billableBlockingPeriod={billableBlockingPeriod}
            />
          ) : (
            <BlockingFeeTable chargeRecord={chargeRecord} />
          )}

          <TableRow>
            <TableCell>
              <Typography variant='subtitle2'>Session Fee</Typography>
            </TableCell>
            <TableCell align='right'>
              <div>
                <Typography variant='subtitle2'>
                  {priceBreakdown?.transaction_fee?.gross_amount &&
                    formatCurrency(priceBreakdown.transaction_fee.gross_amount)}
                </Typography>
              </div>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography variant='subtitle2'>
                Voucher amount applied
              </Typography>
            </TableCell>
            <TableCell align='right'>
              <div>
                <Typography variant='subtitle2'>
                  - {formatCurrency(couponReduction)}
                </Typography>
              </div>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell></TableCell>
            <TableCell align='right'>
              <Typography variant='h6'>
                (VAT is included in the total price)
              </Typography>
              <Typography variant='subtitle2'>
                <span style={{ marginRight: '50px' }}>Total</span>
                <span>
                  {formatCurrency(
                    priceBreakdown?.total_price?.gross_amount ?? 0
                  )}
                </span>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default BreakdownTable;
