import {
  ACNGDeviceMetadata,
  ICsmsChargingStation,
  IEnterpriseChargingStation,
  IFirmwareConsentsHistory,
  IFirmwareInformationModel,
  IHomeChargingStation,
  IHomeChargingStationModel,
  IHomeStation,
  MGWBDeviceMetaData,
} from '../types/user';

import ApiService from './api';
import { isNil, isEmpty } from 'lodash';

interface IRequestParams {
  userId?: string;
  organizationId?: string;
}

type FirmwareConsentsHistoryRequestParams = {
  limit?: number;
  offset?: number;
  user_id?: string;
  station_id?: string;
  firmware_id?: string;
};

enum ApiEndPoints {
  csms = '/mgmt/csms/v2/stations',
  homestations = '/mgmt/homestations/v1/stations',
  ess = '/mgmt/enterprise-stations/v1/stations',
}

const isFulfilled = <T>(
  input: PromiseSettledResult<T>
): input is PromiseFulfilledResult<T> => input.status === 'fulfilled';

const getCsmsStationById = async (
  stationId: string
): Promise<ICsmsChargingStation> => {
  const apiService = new ApiService(`${ApiEndPoints.csms}/${stationId}`);
  const response = await apiService.query();
  return response.data;
};

const getCsmsStationsByIds = async (
  stationIds: string[],
  defaultOffset = 50
): Promise<ICsmsChargingStation[]> => {
  let allData: ICsmsChargingStation[] = [];
  let startIndex = 0;
  let offset = 0;
  do {
    startIndex = offset;
    offset += defaultOffset;
    const currentIds = stationIds.slice(startIndex, offset);
    const apiService = new ApiService(ApiEndPoints.csms, {
      data: {
        station_ids: currentIds.join(','),
        limit: defaultOffset,
      },
    });
    const response = await apiService.query();
    const currentStations = await response.data.stations;
    allData = allData.concat(currentStations);
  } while (stationIds.length > offset);
  return allData;
};

const enrichWithCsmsData = async (
  stations: IHomeStation[],
  isUser: boolean
): Promise<IHomeStation[]> => {
  if (isNil(stations) || isEmpty(stations)) {
    return Promise.resolve([]);
  }
  if (!isUser) {
    return Promise.resolve(stations);
  }
  const csmsStationsPromises = await getCsmsStationsByIds(
    stations.map((homeStation) => homeStation.id)
  );
  const csmsStationsPromisesSettled = await Promise.allSettled(
    csmsStationsPromises
  );
  const csmsStations = csmsStationsPromisesSettled
    .filter(isFulfilled)
    .map((item) => item.value);

  return stations
    .filter((homeStation) =>
      csmsStations.map((csmsStation) => csmsStation.id).includes(homeStation.id)
    )
    .map((homeStation) => {
      const csmsStation = csmsStations.find(
        (station) => station.id === homeStation.id
      );
      return {
        ...homeStation,
        evses: isNil(csmsStation)
          ? [...homeStation.evses]
          : [...csmsStation.evses],
      };
    });
};

const getStations = async ({
  userId,
  organizationId,
}: IRequestParams): Promise<IHomeChargingStationModel> => {
  let url = '';
  const data: { user_id?: string; organization_id?: string; limit: number } = {
    limit: 50,
  };

  if (userId) {
    data.user_id = userId;
    url = ApiEndPoints.homestations;
  }

  if (organizationId) {
    data.organization_id = organizationId;
    url = ApiEndPoints.ess;
  }

  let queryOptions = {
    data: {
      ...data,
      offset: 0,
    },
  };
  let stationModel = {
    total_count: -1,
    stations: [],
  };

  do {
    const apiService = new ApiService(url, queryOptions);
    const response = await apiService.query();
    const responseData = await response.data;
    const enrichedStations = await enrichWithCsmsData(
      responseData.stations,
      !isNil(userId)
    );
    stationModel = {
      ...responseData,
      stations: [...stationModel.stations, ...enrichedStations],
    };

    queryOptions = {
      data: {
        ...data,
        offset: queryOptions.data.offset + 50,
      },
    };
  } while (queryOptions.data.offset < stationModel.total_count);

  return stationModel;
};

const getAdditionalHssDataForStation = async (
  stationId: string
): Promise<IHomeChargingStation> => {
  const apiService = new ApiService(
    `${ApiEndPoints.homestations}/${stationId}`
  );
  const response = await apiService.query();
  return response.data;
};

export const getAdditionalEssDataForStation = async (
  stationId: string
): Promise<IEnterpriseChargingStation> => {
  const apiService = new ApiService(`${ApiEndPoints.ess}/${stationId}`);
  const response = await apiService.query();

  return response.data;
};

export const getStationsBySerialNumber = async (
  serialNumber: string,
  options?: { limit: number }
): Promise<IHomeChargingStationModel> => {
  const data = { station_serial_number: serialNumber };

  const apiService = new ApiService(ApiEndPoints.csms, {
    data: {
      ...data,
      ...options,
    },
  });
  const response = await apiService.query();

  return response.data;
};

const getStationMetaData = async (
  stationId: string
): Promise<MGWBDeviceMetaData | ACNGDeviceMetadata> => {
  const apiService = new ApiService(`/mgmt/csms/v1/stations/${stationId}/twin`);
  const response = await apiService.query();
  return response.data;
};

const getStationFirmwareInformation = async (
  stationId: string
): Promise<IFirmwareInformationModel> => {
  const apiService = new ApiService(
    `${ApiEndPoints.homestations}/${stationId}/firmware-information`
  );
  const response = await apiService.query();
  return response.data;
};

const getAutoUpdateInformation = async (
  stationId: string
): Promise<boolean> => {
  const apiService = new ApiService(`/mgmt/firmware/v1/stations/${stationId}`);
  const response = await apiService.query();
  return response.data.auto_update;
};

const getFirmwareConsentsHistory = async (
  userId?: string,
  stationId?: string,
  firmwareId?: string
): Promise<IFirmwareConsentsHistory> => {
  const limit = 25;
  const data: FirmwareConsentsHistoryRequestParams = {
    limit: limit,
    offset: 0,
    user_id: userId,
    station_id: stationId,
    firmware_id: firmwareId,
  };

  Object.keys(data).forEach(
    (key) =>
      (data[key as keyof FirmwareConsentsHistoryRequestParams] === undefined ||
        data[key as keyof FirmwareConsentsHistoryRequestParams] === null) &&
      delete data[key as keyof FirmwareConsentsHistoryRequestParams]
  );

  const apiService = new ApiService('/mgmt/homestations/v1/firmware/consents', {
    data: {
      ...data,
    },
  });

  const firstResponse = await apiService.query();
  const responseData = firstResponse.data;
  const totalCount = responseData.total_count;

  for (let i = limit; i < totalCount; i = i + limit) {
    const newApiService = new ApiService(
      '/mgmt/homestations/v1/firmware/consents',
      {
        data: {
          ...data,
          offset: i,
        },
      }
    );
    const newResponse = await newApiService.query();
    responseData.firmware_consents.push(...newResponse.data.firmware_consents);
  }

  return responseData;
};

export const isValidWallboxSerialNumber = (input: string): boolean =>
  !/[^a-zA-Z0-9\-_]+/.test(input);

export const StationService = {
  getStations,
  getCsmsStationById,
  getCsmsStationsByIds,
  getStationFirmwareInformation,
  getAdditionalHssDataForStation,
  getAdditionalEssDataForStation,
  getFirmwareConsentsHistory,
  getStationsBySerialNumber,
  getStationMetaData,
  isValidWallboxSerialNumber,
  getAutoUpdateInformation,
};
