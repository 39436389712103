import React from 'react';
import {
  BasicStatus,
  IMappedStatus,
} from '../../shared/StatusIndicator/BasicStatus';
import { useTheme } from '@mui/material/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Tooltip from '../../ui/Tooltip';
import { formatIsoDatesNoSeconds } from '../../../utils/dates';
import { getBlockingLogReasonText } from './blockingReasons';
import { Body } from '@elli-eco/component-library';
import DialogHeadline from '../../shared/Dialogs/DialogHeadline';
import useSharedStyles from '../../shared/useSharedStyles';
import { BlockingLogItem } from '../../../types/subscriber';
import { Typography } from '../../shared/Typography/Typography';

export type PublicChargingAccessStatusProps = {
  isBlocked: boolean;
  hasBlockingLog: boolean;
};

export const PublicChargingAccessStatus = ({
  isBlocked,
  hasBlockingLog,
}: PublicChargingAccessStatusProps) => {
  const theme = useTheme();
  const status = isBlocked
    ? 'Blocked'
    : hasBlockingLog
    ? 'Unblocked'
    : 'Active';
  const valueMapper = (): IMappedStatus => ({
    color: isBlocked ? theme.colorAlertPrimary : theme.colorAccent,
    label: status,
  });
  return <BasicStatus status={null} valueMapper={valueMapper} />;
};

export type BlockingLogItemStatusProps = { isBlocked: boolean };

export const BlockingLogItemStatus = ({
  isBlocked,
}: BlockingLogItemStatusProps) => {
  const theme = useTheme();
  const valueMapper = (): IMappedStatus => ({
    color: isBlocked ? theme.colorAlertPrimary : theme.colorAccent,
    label: isBlocked ? 'Blocked' : 'Unblocked',
  });
  return <BasicStatus status={null} valueMapper={valueMapper} />;
};

export type PublicChargingAccessHeadlineProps = {
  blockingLog: BlockingLogItem[] | undefined;
};

export const PublicChargingAccessHeadline = ({
  blockingLog = [],
}: PublicChargingAccessHeadlineProps) => {
  const classes = useSharedStyles();
  return (
    <div className={classes.labelWrapper}>
      Public Charging Access
      <Tooltip tooltipName='blocking-info'>
        <DialogHeadline headline='Public Charging Access' />
        <Body
          data-testid='public-charging-access-description'
          style={{ marginTop: '16px' }}
        >
          Users can be blocked from public charging, e.g., if they have unpaid
          invoices. Users will need to take action to be unblocked. Before you
          unblock a user, make sure to review the payment setup and status of
          invoices. Do not unblock users with open amounts.
          <br />
          If users are blocked, they can&apos;t pair their RFID-Cards with
          subscriptions.
        </Body>
        {blockingLog.length > 0 && (
          <>
            <Typography
              variant='h2'
              align='center'
              gutterBottom
              style={{ margin: '16px 0 16px 0' }}
            >
              Blocking Log
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label={'blocking-log'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Date and Time</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Reason</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {blockingLog
                    .sort(
                      (a, b) =>
                        Date.parse(b.created_at) - Date.parse(a.created_at)
                    )
                    .map((log, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                          {formatIsoDatesNoSeconds(log.created_at)}
                        </TableCell>
                        <TableCell>
                          <BlockingLogItemStatus isBlocked={log.blocked} />
                        </TableCell>
                        <TableCell>
                          {getBlockingLogReasonText(log.reason)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Tooltip>
    </div>
  );
};
