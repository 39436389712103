import React from 'react';
import { Grid } from '@mui/material';

import useSharedStyles from '../../../shared/useSharedStyles';
import { Typography } from '../../../shared/Typography/Typography';
import BreakdownTable from './BreakdownTable';
import { ICDRMgmtResponse } from '../../../../types/chargingSession';
import PriceBreakdownUnavailable from './PriceBreakdownUnavailable';
import { ChargingCondition } from '../../../../types/chargingCondition';

interface IOwnProps {
  chargeRecord?: ICDRMgmtResponse;
  chargingCondition?: ChargingCondition;
}

const PriceBreakdown: React.FC<IOwnProps> = ({
  chargeRecord,
  chargingCondition,
}) => {
  const sharedClasses = useSharedStyles();

  // The price is defined by the CPO and does not follow our pricing structure.
  // Price breakdown must be not available for fixed price CDR older than March 2024
  // and markup price CDRs without ElliPrice
  const isPriceBreakDownAvailable =
    new Date(chargeRecord?.created_at ?? '2024-03-01') > new Date('2024-03-01');

  return (
    <>
      <Grid item xs={12} sx={{ marginTop: '8px' }}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h4'>Price Breakdown</Typography>
        </div>
      </Grid>
      {!isPriceBreakDownAvailable && <PriceBreakdownUnavailable />}
      {chargeRecord && isPriceBreakDownAvailable && (
        <BreakdownTable
          chargeRecord={chargeRecord}
          chargingCondition={chargingCondition}
        />
      )}
    </>
  );
};

export default PriceBreakdown;
