import { CircularProgress, Grid } from '@mui/material';
import {
  getConnectorOverview,
  IConnectorOverview,
} from '../../../utils/getConnectorOverview';
import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';

import ChargingLocation from './ChargingLocation/ChargingLocation';
import ChargingSessionLogo from '../../../assets/image/chargingSession.svg';
import FaultedSession from './FaultedSession/FaultedSession';
import { StationService } from '../../../services/stations';
import { IChargingRecordViewModel } from '../../../types/chargingRecords';
import { ICsmsChargingStation } from '../../../types/user';
import PrivateOverview from './Private/Overview';
import PublicOverview from './Public/Overview';
import { formatIsoDatesNoSeconds } from '../../../utils/dates';
import useSharedStyles from '../../shared/useSharedStyles';
import useStyles from './useStyles';
import PaymentDetails from './PaymentDetails/PaymentDetails';
import { useChargingLocation } from '../../../state/queries/useChargingLocation';
import { Typography } from '../../shared/Typography/Typography';
import PriceBreakdown from './PriceBreakdown';
import { useGetChargeRecord } from '../../../state/queries/useGetChargeRecord';
import { useGetChargingCondition } from '../../../state/queries/useGetChargingCondition';
import { ISubscriptionModel } from '../../../types/subscriber';
import { useGetTarrif } from '../../../state/queries/useGetTariff';

export type TOwnProps = IPropsFromState & {
  resourceId?: string;
  crumbId?: string;
};

export const ChargeRecordDetails: React.FC<TOwnProps> = ({
  chargingCardState,
  chargingSessionsState,
  resourceId,
  addBreadcrumb,
  breadcrumbState,
  crumbId,
  subscriptionState,
  userState,
}) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const [
    chargingSession,
    setChargingSession,
  ] = useState<IChargingRecordViewModel | null>(null);

  const [currentSubscription, setCurrentSubscription] = useState<
    ISubscriptionModel
  >();

  useEffect(() => {
    if (chargingSession && subscriptionState && subscriptionState.data) {
      setCurrentSubscription(
        subscriptionState.data.find(
          (sub) => sub.id === chargingSession.subscription_id
        )
      );
    }
  }, [chargingSession]);

  const [recordType, setRecordType] = useState<string>('');

  const [
    currentStation,
    setCurrentStation,
  ] = useState<ICsmsChargingStation | null>(null);

  const { data: chargingCondition } = useGetChargingCondition(
    chargingSession?.charging_condition_id
  );

  const { data: tariff } = useGetTarrif(
    currentSubscription?.tariff_id,
    currentSubscription?.base_condition_id
  );

  const {
    data: chargeRecord,
    isLoading: isLoadingChargeRecord,
  } = useGetChargeRecord(
    chargingSession?.external_cdr_id,
    chargingSession?.cpo_party_id,
    chargingSession?.cpo_country_code
  );

  const { data: currentLocation } = useChargingLocation({
    locationId: chargingSession?.location_id,
    evseId: chargingSession?.location_evse_id,
  });

  const [
    connectorOverview,
    setConnectorOverview,
  ] = useState<IConnectorOverview | null>(null);

  useEffect(() => {
    const overview = getConnectorOverview({
      currentStation,
      currentSession: chargingSession,
    });

    if (overview) {
      setConnectorOverview(overview);
    }
  }, [chargingSession, currentStation]);

  useEffect(() => {
    if (!resourceId) {
      return;
    }

    const chargingSessions = chargingSessionsState?.data;

    if (chargingSessions) {
      const currentSession = chargingSessions.find(
        (cc) => cc.id === resourceId
      );

      if (currentSession) {
        setChargingSession(currentSession);
        setRecordType(currentSession.type);
      }
    }
  }, [resourceId, chargingSessionsState]);

  useEffect(() => {
    if (chargingSession) {
      const getCurrentStation = async () => {
        try {
          const station = await StationService.getCsmsStationById(
            chargingSession.location_id
          );

          setCurrentStation(station);
        } catch (error) {
          setCurrentStation(null);
        }
      };

      if (chargingSession.location_id) {
        getCurrentStation();
      }
    }
  }, [chargingSession]);

  return (
    chargingSession && (
      <div
        className={sharedClasses.cardElement}
        data-testid='charging-session-details-component'
      >
        <div className={classes.chargingCardDetailsHeader}>
          <div className={classes.titleWrapper}>
            <div className={classes.logoContainer}>
              <img src={ChargingSessionLogo} alt='charging session logo' />
            </div>
            <Typography
              variant='h4'
              classes={{
                root: classes.panelTitle,
              }}
            >
              Charging Record -{' '}
              {recordType === 'private' || recordType === 'business' ? (
                <span data-testid='id-header'>{chargingSession.id}</span>
              ) : (
                <span data-testid='date-header'>
                  {formatIsoDatesNoSeconds(chargingSession.start_date_time)}
                </span>
              )}
            </Typography>
          </div>
          {chargingSession.session_faulted && <FaultedSession />}
        </div>

        <Grid
          container
          justifyContent='space-between'
          alignItems='flex-start'
          direction='row'
        >
          {recordType === 'public' ? (
            <PublicOverview
              chargingSession={chargingSession}
              chargingCondition={chargingCondition}
              tariff={tariff}
            />
          ) : (
            <PrivateOverview
              chargingSession={chargingSession}
              connectorOverview={connectorOverview}
            />
          )}
          <PaymentDetails chargingSession={chargingSession} />
          <ChargingLocation
            chargingCardState={chargingCardState}
            addBreadcrumb={addBreadcrumb}
            breadcrumbState={breadcrumbState.breadcrumbs}
            crumbId={crumbId}
            chargingSession={chargingSession}
            userState={userState}
            currentStation={currentStation}
            currentLocation={currentLocation ?? null}
          />
          {isLoadingChargeRecord ? (
            <CircularProgress color='primary' />
          ) : (
            <PriceBreakdown
              chargeRecord={chargeRecord}
              chargingCondition={chargingCondition}
            />
          )}
        </Grid>
      </div>
    )
  );
};

export default connector(ChargeRecordDetails);
