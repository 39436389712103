import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  isValidWallboxSerialNumber,
  StationService,
} from '../../services/stations';
import {
  ACNGDeviceMetadata,
  ICsmsChargingStation,
  IHomeChargingStationMetaData,
  MGWBDeviceMetaData,
} from '../../types/user';
import { getStationModel } from '../../utils/stationUtils';

// From the user installation manual; https://www.elli.eco/en/downloads
export const ACNG_CONFIG_IP_ADDRESS = '10.0.2.1';
export const MGWB_CONFIG_IP_ADDRESS = '192.168.123.4';

const mapMGWBMetadataToDomain = (
  metaData: MGWBDeviceMetaData,
  station: ICsmsChargingStation
): IHomeChargingStationMetaData => ({
  model: getStationModel(station.model, station.model_variant),
  serialNumber: station.station_serial_number,
  pairingCode: station.pairing_code,
  wifiHotSpotSSID: metaData.device_metadata?.custom?.WifiInstallerApSsid,
  wifiHotSpotPassword:
    metaData.device_metadata?.custom?.WifiInstallerApPassword,
  networkHostName: metaData.device_metadata?.custom?.ComBoardHostName,
  configurationIpAdress:
    station.model === 'EVBOX_MGWB' ? MGWB_CONFIG_IP_ADDRESS : undefined,
  configurationIpPassword: metaData.device_metadata?.custom?.WebConfigPassword,
  pukCode: metaData.device_metadata?.custom?.PukCode,
});

const mapACNGMetadataToDomain = (
  metadata: ACNGDeviceMetadata,
  station: ICsmsChargingStation
): IHomeChargingStationMetaData => ({
  model: getStationModel(station.model, station.model_variant),
  serialNumber: station.station_serial_number,
  pairingCode: station.pairing_code,
  wifiHotSpotSSID: metadata.device_metadata?.WifiInstallerApSsid,
  wifiHotSpotPassword: metadata.device_metadata?.WifiHotspotPassword,
  networkHostName: metadata.device_metadata?.ComBoardHostName,
  configurationIpAdress: ACNG_CONFIG_IP_ADDRESS,
  configurationIpPassword: metadata.device_metadata?.WifiInstallerApPassword,
  webConfigPassword: metadata.device_metadata?.WebConfigPassword,
  pukCode: metadata.device_metadata?.PukCode,
});

const isACNGDeviceMetadata = (
  metadata: unknown,
  stationModel?: string
): metadata is ACNGDeviceMetadata => {
  return (
    !!stationModel &&
    stationModel !== '' &&
    // ACNG models contain the manufacturer and the variant type info. E.g., ESYSTEMS_AC02_PRO_22
    stationModel.toUpperCase().startsWith('ESYSTEMS') &&
    !!metadata
  );
};

export const getWallboxMetadataBySerialNumber = async (
  serialNumber: string
): Promise<IHomeChargingStationMetaData | undefined> => {
  const data = await StationService.getStationsBySerialNumber(serialNumber, {
    limit: 1,
  });

  if (
    data.stations.length === 0 ||
    serialNumber !== data.stations[0].station_serial_number?.toUpperCase()
  ) {
    return undefined;
  }

  const station = await StationService.getCsmsStationById(data.stations[0].id);
  const metaData = await StationService.getStationMetaData(station.id);

  return isACNGDeviceMetadata(metaData, station.model)
    ? mapACNGMetadataToDomain(metaData, station)
    : mapMGWBMetadataToDomain(metaData, station);
};

export const useWallboxConfigDataQuery = (
  serialNumber: string
): UseQueryResult<IHomeChargingStationMetaData | undefined> => {
  return useQuery(
    ['wallboxConfigData', serialNumber],
    async () => {
      return getWallboxMetadataBySerialNumber(serialNumber);
    },
    {
      enabled: serialNumber !== '' && isValidWallboxSerialNumber(serialNumber),
    }
  );
};
