import { Grid } from '@mui/material';
import Magnifier from '../../../../assets/image/magnifyingGlass.svg';
import useTheme from '@mui/styles/useTheme';
import React from 'react';
import { Typography } from '../../../shared/Typography/Typography';

const PriceBreakdownUnavailable: React.FC = () => {
  const theme = useTheme();

  return (
    <Grid container alignItems={'center'} direction={'column'}>
      <img src={Magnifier} />
      <Typography variant='h1' sx={{ color: theme.textSecondary }}>
        Price Breakdown not available
      </Typography>
    </Grid>
  );
};

export default PriceBreakdownUnavailable;
