import { TableRow, TableCell } from '@mui/material';
import React from 'react';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';
import { ICDRMgmtResponse } from '../../../../types/chargingSession';
import { formatIsoTime } from '../../../../utils/dates';
import { formatUnit } from '../../../../utils/numbers';

export interface BlockingFeeTableProps {
  chargeRecord: ICDRMgmtResponse;
}

export const BlockingFeeTable = ({ chargeRecord }: BlockingFeeTableProps) => {
  const classes = useStyles();
  const priceBreakdown = chargeRecord.price_breakdown;
  priceBreakdown?.blocking_fee?.blocking_fee_items;

  if (!priceBreakdown || !priceBreakdown.blocking_fee) {
    return <></>;
  }

  const currency = chargeRecord.blocking_fee_currency;
  const formatCurrency = (value: number) => formatUnit(value, currency);

  const blockingFeeItems = priceBreakdown.blocking_fee.blocking_fee_items.map(
    (item) => {
      const duration = item.blocking_duration_minutes;
      const price = item.blocking_price_per_minute.gross_amount;
      const amount = duration * price;

      return {
        start: formatIsoTime(item.start_time),
        end: formatIsoTime(item.end_time),
        duration: `${duration.toFixed(0)} min`,
        price: `${formatCurrency(price)}/min`,
        amount: formatCurrency(amount),
      };
    }
  );

  return (
    <>
      <TableRow>
        <TableCell size='small'>
          <Typography variant='subtitle2'>Time-based fees</Typography>
        </TableCell>
        <TableCell align='right' size='small'>
          <Typography variant='subtitle2'>
            {formatCurrency(chargeRecord.blocking_fee ?? 0)}{' '}
          </Typography>
        </TableCell>
      </TableRow>

      {blockingFeeItems.map(({ start, end, duration, price, amount }, key) => (
        <TableRow key={key}>
          <TableCell size='small' className={classes.blockingFeeCells}>
            <Typography variant='body2'>
              {start} - {end}
            </Typography>
          </TableCell>
          <TableCell
            align='right'
            size='small'
            className={classes.blockingFeeCells}
          >
            <Typography variant='h6'>
              {duration} * {price} = {amount}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
