import ApiService from './api';
import { IUserModel, IUserSearchModel } from '../types/user';

interface IRequestParams {
  userId: string;
}

interface IFindUserParams {
  query: string;
}

interface IUpdateUserParams {
  user_id: string;
  first_name?: string;
  last_name?: string;
}

export type IUpdateUserProfile = Omit<IUpdateUserParams, 'user_id'>;

const list = async (): Promise<IUserModel[]> => {
  const apiService = new ApiService('/mgmt/iam/users/');
  const response = await apiService.query();
  return response.data;
};

const getById = async ({ userId }: IRequestParams): Promise<IUserModel> => {
  const apiService = new ApiService(`/mgmt/iam/users/${userId}`);
  const response = await apiService.query();
  return response.data;
};

const findUser = async ({
  query,
}: IFindUserParams): Promise<IUserSearchModel[]> => {
  const apiService = new ApiService(`/mgmt/customer/v1/users/search`, {
    data: { query },
  });
  const response = await apiService.query();
  return response.data;
};

const updateUser = async ({
  user_id,
  first_name,
  last_name,
}: IUpdateUserParams): Promise<void> => {
  const apiService = new ApiService(`/mgmt/iam/v1/users/${user_id}`, {
    data: {
      ...(first_name && { first_name: first_name }),
      ...(last_name && { last_name: last_name }),
    },
    method: 'PATCH',
  });
  await apiService.query();
};

const activateUser = async (params: IRequestParams): Promise<void> => {
  const apiService = new ApiService(
    `/mgmt/iam/v1/users/${params.userId}/activate`,
    {
      method: 'POST',
    }
  );
  await apiService.query();
};

const deactivateUser = async (params: IRequestParams): Promise<void> => {
  const apiService = new ApiService(
    `/mgmt/iam/v1/users/${params.userId}/deactivate`,
    {
      method: 'POST',
    }
  );
  await apiService.query();
};

export const UsersService = {
  list,
  getById,
  findUser,
  updateUser,
  activateUser,
  deactivateUser,
};
