import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  form: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  button: { margin: '0 32px' },
  actions: { textAlign: 'center' },
  outerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: -16,

    '&> *': {
      margin: 16,
    },
  },
});
