import React, { useState } from 'react';
import AcceptIcon from '../../../../assets/image/acceptIcon.svg';
import AlertIcon from '../../../../assets/image/alert.svg';
import { IUserModel } from '../../../../types/user';
import SuccessMessage from '../../../shared/Dialogs/SuccessMessage';
import DeactivateForm from './DeactivateForm';

export interface IOwnProps {
  user: IUserModel;
}

export const DeactivateModal: React.FC<IOwnProps> = ({ user }) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isFail, setIsFail] = useState<string>('');
  const handleSuccess = () => setIsSuccess(true);
  const handleFail = (reason: unknown) => {
    const errMessage = (reason as {
      response?: { data?: { errors?: { message?: string }[] } };
    })?.response?.data?.errors?.[0]?.message;

    if (reason instanceof Error) {
      setIsFail(errMessage || reason.message);
    } else {
      setIsFail('something went wrong');
    }
  };

  if (isSuccess) {
    return (
      <SuccessMessage
        headline={'Activate/Deactivate User'}
        subHeadline={''}
        icon={AcceptIcon}
        iconAltText={'icon'}
      />
    );
  }
  if (isFail) {
    return (
      <SuccessMessage
        headline={'Activation/Deactivation of User failed'}
        subHeadline={isFail}
        icon={AlertIcon}
        iconAltText={'icon'}
      />
    );
  }
  return (
    <DeactivateForm user={user} onSuccess={handleSuccess} onFail={handleFail} />
  );
};
