import React, { useContext, useState } from 'react';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import connector, { IPropsFromState } from '../../../Connector/Connector';
import { Alert, Button, FormControl, InputLabel } from '@mui/material';
import MultilineButton from '../../../shared/Buttons/MultilineButton';
import { SubscribersService } from '../../../../services/subscribers';
import { toast } from 'react-hot-toast';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useStyles } from './useStyles';
import DialogHeadline from '../../../shared/Dialogs/DialogHeadline';
import { DialogContextState } from '../../../shared/Dialogs/context/ActionDialogContext';
import { BlockingReasons } from '../../../../utils/blockingReasons';

const BlockPublicChargingAccessDialog: React.FC<IPropsFromState> = ({
  subscriberState,
  getSubscriberOnlyRequest,
}) => {
  const { t, terms } = useTypedTranslation();
  const { handleCloseDialog } = useContext(DialogContextState);
  const [blockingReason, setBlockingReason] = React.useState(
    'MANUAL_FRAUD_REVIEW' // set this as initial value because it was used all the time
  );

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [issue, setIssue] = React.useState(false);

  const classes = useStyles();

  const subscriber = subscriberState.data;

  const onConfirm = async () => {
    if (subscriber && !subscriber.subscriber_blocked) {
      if (blockingReason !== '') {
        try {
          setInProgress(true);
          await SubscribersService.blockPublicChargingAccess(
            subscriber.id,
            blockingReason
          );
          getSubscriberOnlyRequest({
            userId: subscriber.iam_id,
          });
          handleCloseDialog && handleCloseDialog();
        } catch (err) {
          setInProgress(false);
          toast.error(t(terms.general_errors.generic));
          throw new Error(
            'Unexpected error. Could not block public charging access.'
          );
        }
      } else {
        setIssue(true);
      }
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setBlockingReason(event.target.value as string);
  };

  return (
    <div className={classes.outerWrapper}>
      <div>
        <DialogHeadline
          headline={t(terms.public_charging_access.actions.block.headline)}
        />
      </div>
      {t(terms.public_charging_access.actions.block.text)}
      <FormControl className={classes.form}>
        <InputLabel id='selected-blocking-reason-label'>
          Selected blocking reason
        </InputLabel>
        <Select
          labelId={'selected-blocking-reason-label'}
          id={'blocking-reason-select'}
          value={blockingReason}
          onChange={handleChange}
          data-testid={'blocking-reason-select'}
        >
          {BlockingReasons.map((br) => (
            <MenuItem key={br} value={br}>
              {br}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.actions}>
        <Button
          color='primary'
          variant='text'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          {t(terms.general.cancel)}
        </Button>
        <MultilineButton
          inProgress={inProgress}
          handleClick={onConfirm}
          mainText={'Confirm'}
          className={classes.button}
        />
      </div>
      {issue && (
        <Alert severity='error' data-testid='enableDisableButtonErrorAlert'>
          Please select a blocking reason before blocking a customer
        </Alert>
      )}
    </div>
  );
};

export default connector(BlockPublicChargingAccessDialog);
