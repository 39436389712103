export enum ConnectorPowerType {
  AC_1_PHASE = 'AC_1_PHASE',
  AC_3_PHASE = 'AC_3_PHASE',
  DC = 'DC',
}

export const PricingModel = {
  Fixed: 'fixed_pricing',
  Markup: 'markup_pricing',
} as const;

type BlockingFeeItem = {
  blocking_duration_minutes: number;
  start_time: string;
  end_time: string;
  blocking_price_per_minute: {
    gross_amount: number;
    net_amount: number;
    vat_amount: number;
  };
};

export interface IChargingSessionModel {
  billing_status: string;
  blocking_fee?: number;
  blocking_fee_currency?: string;
  blocking_fee_duration_in_minutes?: number;
  blocking_fee_excl_tax?: number;
  blocking_fee_vat_amount?: number;
  blocking_fee_vat_group?: string;
  blocking_fee_vat_percentage?: number;
  charging_condition_id?: string | null;
  coupon_reduction?: number;
  cpo_country_code?: string;
  cpo_party_id?: string;
  cpo_price: number;
  cpo_price_currency?: string;
  cpo_price_exchanged?: number;
  cpo_pricing_source?: string;
  created_at: string;
  currency?: string;
  elli_connector_id?: string | null;
  elli_evse_uid?: string | null;
  elli_location_id?: string | null;
  end_date_time: string;
  error: string | null;
  exchange_rate?: number;
  external_cdr_id: string;
  discount?: number;
  guaranteed_cpo_price?: number;
  guaranteed_cpo_price_currency?: string;
  id: string;
  location_address?: string;
  location_city?: string | null;
  location_connector_power_type: string;
  location_connector_standard?: string;
  location_coordinates_latitude?: string;
  location_coordinates_longitude?: string;
  location_country: string;
  location_evse_id: string;
  location_name: string;
  location_operator_name?: string | null;
  location_postal_code?: string | null;
  local_currency?: string;
  ocpi_version?: string | null;
  rfid_card_id?: string;
  start_date_time: string;
  subscription_id?: string;
  token_id: string;
  token_type: string;
  total_energy: number;
  total_price_excl_tax?: number;
  total_price?: number;
  total_time: number;
  updated_at: string;
  vat_amount: number;
  vat_percentage: number;
  total_cost?: number;
  auth_metod?: string;
  last_updated?: string;
  stop_date_time?: string;
  ocpi_object: IOcpiObjectModel | null;
  type?: string;
}

export interface ICDRMgmtResponse {
  billing_status: string;
  cpo_price: number;
  cpo_price_currency: string;
  external_cdr_id: string;
  location_country: string;
  blocking_fee?: number;
  blocking_fee_currency: string;
  blocking_fee_excl_tax?: number;
  blocking_fee_vat_amount?: number;
  blocking_fee_vat_group?: string;
  blocking_fee_vat_percentage?: number;
  charging_condition_id?: string;
  created_at: string;
  cpo_country_code?: string;
  cpo_party_id?: string;
  cpo_price_exchanged?: number;
  cpo_pricing_source?: string;
  elli_connector_id?: string;
  elli_evse_uid?: string;
  elli_location_id?: string;
  authorization_reference?: string;
  tenant_id?: string;
  end_date_time: string;
  error?: string;
  exchange_rate?: number;
  guaranteed_cpo_price?: number;
  guaranteed_cpo_price_currency?: string;
  last_processed_at?: string;
  location_address?: string;
  location_city?: string;
  location_connector_power_type: ConnectorPowerType;
  location_connector_standard?: string;
  location_coordinates_latitude?: string;
  location_coordinates_longitude?: string;
  location_name?: string;
  location_operator_name?: string;
  location_postal_code?: string;
  ocpi_object?: IOcpiObjectModel;
  ocpi_version?: string;
  roaming_partner?: string;
  start_date_time: string;
  timezone?: string;
  total_time: number;
  total_energy: number;
  total_price?: number;
  total_price_currency?: string;
  total_price_excl_tax?: number;
  vat_amount?: number;
  vat_group?: string;
  vat_percentage?: number;

  local_currency?: string;
  pricing_model?: typeof PricingModel.Fixed | typeof PricingModel.Markup;
  price_breakdown?: {
    vat_percentage?: number;
    currency?: string;
    vat_group?: string;
    total_price?: {
      gross_amount: number;
      net_amount: number;
      vat_amount: number;
    };
    blocking_fee?: {
      total_blocking_minutes: number;
      free_blocking_minutes: number;
      billed_blocking_minutes: number;
      blocking_fee_items: BlockingFeeItem[];
    };
    charging_fee?: {
      gross_amount: number;
      net_amount: number;
      vat_amount: number;
    };
    transaction_fee?: {
      gross_amount: number;
      net_amount: number;
      vat_amount: number;
    };
    charging_fee_rate?: {
      gross_amount: number;
    };
    coupon_reduction?: number;
  };
}

export interface IOcpiObjectModel {
  id: string;
  auth_id: string;
  currency: string;
  location: ILocationModel;
  total_cost: number;
  total_time: number;
  auth_method: string;
  last_updated: string;
  total_energy: number;
  stop_date_time: string;
  start_date_time: string;
  charging_periods?: IChargingPeriodsModel[];
}

export interface ILocationAddress {
  city: string;
  country: string;
  street: string;
  zip: string;
}

export interface ILocationModel {
  address?: ILocationAddress | string;
  operator?: {
    name?: string;
  };
  geo_location?: {
    lat: number;
    long: number;
  };
  charging_spots: IChargingSpotModel[];
  last_updated?: string;
  location_id: string;
  distance?: number;
}

export interface IChargingSpotModel {
  charging_spot_id?: string;
  connectors?: IConnectorModel[];
  evse_id: string;
  status?: string;
}

export interface IEvseModel {
  uid: string;
  status: string;
  evse_id: string;
  connectors: IConnectorModel[];
  last_updated?: string;
}

export interface IConnectorModel {
  connector_id?: string;
  connector_type?: string;
  connector_type_id?: number;
  current_type?: string;
  max_electric_power?: number;
}

export interface IChargingPeriodsModel {
  dimensions?: [
    {
      type?: string;
      volume?: number;
    }
  ];
  start_date_time?: string;
}
